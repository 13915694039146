import gql from 'graphql-tag'

export const CreateCommission = gql`
  mutation createCommission($input: CreateCommissionInput!) {
    createCommission(input: $input) {
      success
    }
  }
`

export const EditCommission = gql`
  mutation editCommission($input: EditCommissionInput!) {
    editCommission(input: $input) {
      success
    }
  }
`

export const DeleteCommission = gql`
  mutation deleteCommission($input: DeleteCommissionInput!) {
    deleteCommission(input: $input) {
      success
    }
  }
`
