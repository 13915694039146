<template>
  <v-form
    v-model="valid"
    lazy-validation
    ref="form"
    >
    <div
      class="d-flex flex-column"
      >
      <v-text-field
        v-model.number="attributes.amount"
        outlined
        label="Porcentaje de comisión"
        hide-details="auto"
        :rules="[
        v => !!v || 'Campo obligatorio',
        v => (v || 0) >= 0 || 'La comisión debe ser mayor o igual a 0',
        v => (v || 100) <= 100 || 'La comisión debe ser menor o igual a 100',
        ]"
        ></v-text-field>

      <v-btn
        tile
        depressed
        color="primary"
        @click="edit"
        class="mt-3"
        >
        Guardar
      </v-btn>
    </div>

    <v-snackbar
      color="success"
      v-model="success"
      >
      <div
        class="fill-width text-center"
        >
        ¡Comisión editada con éxito!
      </div>
    </v-snackbar>
  </v-form>
</template>

<script>
import { EditCommission } from '@/graphql/mutations/commissions' 

export default {
  data: () => ({
    valid: true,
    success: false,
    attributes: {
      amount: null
    }
  }),

  props: {
    commission: {
      required: true,
      type: Object
    }
  },

  created () {
    this.attributes.amount = this.commission.amount
  },

  methods: {
    edit () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: EditCommission,
          variables: {
            input: {
              attributes: this.attributes,
              id: this.commission.id
            }
          }
        }).then ( res => {
          this.success = true
          this.$emit('reload')
        })
      }
    }
  }
}
</script>
